import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import api from 'services/apiService';

const fetchCertificates = createAsyncThunk('certificate/fetchCertificates', async ({ placeId, ...query }) => {
  return api.get(`/places/${placeId}/billings/certificates?${queryString.stringify(query)}`);
});

const addCertificate = createAsyncThunk('certificate/add', async ({ placeId, data }) => {
  return api.post(`/places/${placeId}/billings/certificates`, data);
});

const editCertificate = createAsyncThunk('certificate/edit', async ({ placeId, billingId, data }) => {
  return api.put(`/places/${placeId}/billings/certificates/${billingId}`, data);
});

const findCertificate = createAsyncThunk('certificate/search', async ({ placeId, uuid }) => {
  return api.post(`/places/${placeId}/certificates/search`, { uuid });
});

const deleteCertificate = createAsyncThunk('certificate/delete', async ({ placeId, certificateId }) => {
  return api.delete(`/places/${placeId}/billings/certificates/${certificateId}`);
});

export default {
  fetchCertificates,
  addCertificate,
  editCertificate,
  findCertificate,
  deleteCertificate,
};
