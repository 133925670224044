import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import mova from 'mova';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import FormikTextInput from '@form/FormikTextInput';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { integrationSelectors, integrationThunks } from 'state/ducks/integration';
import { toastrActions } from 'state/ducks/toastr';
import FormikSelect from '../../../../../form/FormikSelect';
import FormikCheckbox from '@form/FormikCheckbox';

const t = mova.ns('components.Integrations');
const tSettings = mova.ns('components.Settings');

const syrveSchema = Yup.object().shape({
  syrveApiKey: Yup.string().required(() => t('errors.required')),
});

const SyrveIntegrations = () => {
  const dispatch = useDispatch();

  const activePlace = useSelector(placeSelectors.getActivePlace());
  const syrveOrganisations = useSelector(integrationSelectors.getSyrveOrganisations());
  const syrveTerminals = useSelector(integrationSelectors.getSyrveTerminals());

  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const [syrveApiFailed, setSyrveApiFailed] = useState(false);
  const syrveConnected = activePlace.connections?.syrve?.apiKey;

  useEffect(() => {
    if (activePlace.connections?.syrve?.apiKey) {
      dispatch(
        integrationThunks.getSyrveOrganisations({
          placeId: activePlace.id,
          apiKey: activePlace.connections?.syrve?.apiKey,
        }),
      );
    }
    if (activePlace.connections?.syrve?.organisationId) {
      dispatch(
        integrationThunks.getSyrveTerminals({
          placeId: activePlace.id,
          apiKey: activePlace.connections?.syrve?.apiKey,
          organisationId: activePlace.connections?.syrve?.organisationId,
        }),
      );
    }
  }, [activePlace.id, dispatch]); // eslint-disable-line

  const connectSyrve = async data => {
    if (data.syrveApiKey && data.syrveOrganization && data.syrveTerminalId) {
      const result = await dispatch(
        placeThunks.updatePlaceConnections({
          id: activePlace.id,
          connections: {
            syrve: {
              apiKey: data.syrveApiKey,
              organisationId: data.syrveOrganization,
              terminalGroupId: data.syrveTerminalId,
              enableBanquetPreOrder: !!data.enableBanquetPreOrder,
              enablePaymentsOrder: !!data.enablePaymentsOrder,
              enableSyncReservations: !!data.enableSyncReservations,
            },
          },
        }),
      );
      if (result?.payload?.connections?.syrve?.apiKey !== data.syrveApiKey) {
        setSyrveApiFailed(true);
      } else {
        setSyrveApiFailed(false);
        await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
      }
    } else if (data.syrveApiKey && data.syrveOrganization) {
      dispatch(
        integrationThunks.getSyrveTerminals({
          placeId: activePlace.id,
          apiKey: data.syrveApiKey,
          organisationId: data.syrveOrganization,
        }),
      );
    } else if (data.syrveApiKey) {
      dispatch(
        integrationThunks.getSyrveOrganisations({
          placeId: activePlace.id,
          apiKey: data.syrveApiKey,
        }),
      );
    }
  };

  const resetSyrve = async () => {
    await dispatch(
      placeThunks.updatePlaceConnections({
        id: activePlace.id,
        connections: {
          syrve: null,
        },
      }),
    );
    await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
  };

  const loadMenu = async () => {
    const result = await dispatch(integrationThunks.syncSyrveMenu(activePlace.id));
    if (result?.success) {
      dispatch(toastrActions.showToastr({ message: tSettings('messages.menuConnected') }))
    } else if (result?.error) {
      dispatch(toastrActions.showToastr({ variant: 'error', message: result.error?.message || result.error }))
    }
  }

  return (
    <Card sx={{ mt: 4 }}>
      <Formik
        initialValues={{
          syrveApiKey: activePlace.connections?.syrve?.apiKey || '',
          syrveOrganization: activePlace.connections?.syrve?.organisationId || '',
          syrveTerminalId: activePlace.connections?.syrve?.terminalGroupId || '',
          enableBanquetPreOrder: !!activePlace.connections?.syrve?.enableBanquetPreOrder,
          enablePaymentsOrder: !!activePlace.connections?.syrve?.enablePaymentsOrder,
          enableSyncReservations: !!activePlace.connections?.syrve?.enableSyncReservations,
        }}
        onSubmit={connectSyrve}
        validationSchema={syrveSchema}
      >
        {formik => (
          <Form>
            <Typography variant='h3' sx={{ mb: 2 }}>
              {t('syrveService')}
            </Typography>
            <Box
              display='flex'
              alignItems={mobileView ? 'flex-start' : 'center'}
              justifyContent='space-between'
              flexDirection={mobileView ? 'column' : 'row'}
            >
              <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
                {t('syrveKey')}
              </Typography>
              <FormikTextInput sx={{ flexGrow: 3 }} name='syrveApiKey' fullWidth placeholder={t('syrveKey')} />
            </Box>
            {syrveOrganisations.length > 0 && (
              <Box
                my={1}
                display='flex'
                alignItems={mobileView ? 'flex-start' : 'center'}
                justifyContent='space-between'
                flexDirection={mobileView ? 'column' : 'row'}
              >
                <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
                  {t('syrveOrganization')}
                </Typography>
                <Box flexGrow={5}>
                  <FormikSelect
                    fullWidth
                    name='syrveOrganization'
                    items={[
                      ...syrveOrganisations.map(({ id, name }) => ({
                        id,
                        name,
                        value: id,
                      })),
                    ]}
                    labelPath='name'
                  />
                </Box>
              </Box>
            )}
            {syrveTerminals.length > 0 && (
              <Box
                my={1}
                display='flex'
                alignItems={mobileView ? 'flex-start' : 'center'}
                justifyContent='space-between'
                flexDirection={mobileView ? 'column' : 'row'}
              >
                <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
                  {t('syrveTerminal')}
                </Typography>
                <Box flexGrow={5}>
                  <FormikSelect
                    fullWidth
                    name='syrveTerminalId'
                    items={[
                      ...syrveTerminals[0].items.map(({ id, name }) => ({
                        id,
                        name,
                        value: id,
                      })),
                    ]}
                    labelPath='name'
                  />
                </Box>
              </Box>
            )}
            {syrveConnected && (
              <>
                <Box display='flex' alignItems='center' mt={1}>
                  <FormikCheckbox name='enableSyncReservations' label={t('enableSyncReservations')} />
                </Box>
                <Box display='flex' alignItems='center' mt={1}>
                  <FormikCheckbox name='enableBanquetPreOrder' label={t('enableBanquetPreOrder')} />
                </Box>
                <Box display='flex' alignItems='center' mt={1}>
                  <FormikCheckbox name='enablePaymentsOrder' label={t('enablePaymentsOrder')} />
                </Box>
                <Box display='flex' alignItems='center' mt={1}>
                  <Icon type='done' sx={{ mr: 1 }} />
                  <Typography variant='h4' color='success.main'>
                    {t('connected')}
                  </Typography>
                </Box>
              </>
            )}
            {syrveApiFailed && (
              <Box display='flex' alignItems='center' mt={1}>
                <Icon type='warning' sx={{ mr: 1 }} color={theme.palette.secondary.main} />
                <Typography variant='h4'>{t('syrveApiFailed')}</Typography>
              </Box>
            )}
            <Box display='flex' justifyContent='center' mt={2} gap={2}>
              {(syrveApiFailed || (!syrveConnected && !syrveOrganisations.length)) && (
                <Button type='submit'>{t('loadSyrveOrganisations')}</Button>
              )}
              {!syrveApiFailed && !syrveConnected && !!syrveOrganisations.length && !syrveTerminals.length && (
                <Button type='submit'>{t('loadSyrveTerminals')}</Button>
              )}
              {!syrveApiFailed && !!syrveOrganisations.length && !!syrveTerminals.length && (
                <Button type='submit'>{t('connect')}</Button>
              )}
              {(syrveConnected || true) && <Button onClick={loadMenu}>{t('loadSyrveMenu')}</Button>}
              <Button
                type='reset'
                color='primary'
                onClick={async () => {
                  await resetSyrve();
                  formik.resetForm();
                }}
              >
                {t('reset')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default SyrveIntegrations;
