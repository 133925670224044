import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import Button from '@components/Button/Button';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';

const t = mova.ns('base');

const ModalActions = ({ onClose, submitForm, submitTitle = t('save'), submitProps = {} }) => {
  const smallView = useMediaQuery(theme => theme.breakpoints.down('xssm'));
  return (
    <Box display='flex' justifyContent='flex-end' flexWrap='wrap' sx={{ width: '100%' }} gap={2}>
      <Button fullWidth={smallView} variant='outlined' color='primary' size='large' onClick={onClose}>
        {t('cancel')}
      </Button>
      <Button fullWidth={smallView} size='large' onClick={submitForm} {...submitProps}>
        {submitTitle}
      </Button>
    </Box>
  );
};

ModalActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

export default memo(ModalActions);
